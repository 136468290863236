/* Webdev.css */
/* Webdev.css */

.card {
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transitions for smooth effect */
  }
  
  .card:hover {/* Add shadow on hover */
    box-shadow: 0 0 50px #06e7f7fa;
    transform: scale(1.05); /* Scale up slightly on hover */
  }
  